import * as React from 'react';
import { Spinner } from '@cian/ui-kit';

import * as styles from './styles.css';

interface IConsultantCianChatButtonProps {
  isLoading: boolean;
  onClick(): void;
}

export const ConsultantCianChatButton = ({ onClick, isLoading }: IConsultantCianChatButtonProps) => {
  return (
    <button data-testid="consultantCianChatButton" className={styles['button']} onClick={onClick}>
      {isLoading ? (
        <div className={styles['loader']}>
          <Spinner size={20} color="white_100" />
        </div>
      ) : (
        <div className={styles['content']}>Написать консультанту</div>
      )}
    </button>
  );
};
