import * as React from 'react';
import { ArticleHeading2, ModalWindow } from '@cian/ui-kit';

import * as styles from './ConsultantCianChatErrorPopup.css';

interface IConsultantCianChatErrorPopupProps {
  onClose(): void;
  open: boolean;
}

export const ConsultantCianChatErrorPopup: React.FC<IConsultantCianChatErrorPopupProps> = props => {
  const { onClose, open } = props;

  return (
    <ModalWindow open={open} onClose={onClose} width="100%" maxWidth={448}>
      <div className={styles['container']} data-testid="ConsultantCianChatErrorPopup">
        <div className={styles['title']}>
          <ArticleHeading2 as="h2">К сожалению, этот чат вам пока недоступен</ArticleHeading2>
        </div>
      </div>
    </ModalWindow>
  );
};
