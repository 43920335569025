import { ca } from '@cian/analytics';
import { IJsonQuery } from '../../../types/jsonQuery';

export const trackEntryPointClick = ({ jsonQuery }: { jsonQuery: IJsonQuery }) =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Chat_kpn_entry',
    action: 'Click_sopr',
    label: 'Click',
    sc: jsonQuery,
    useLastProducts: true,
  });

export const trackChatCloseClick = ({ jsonQuery }: { jsonQuery: IJsonQuery }) =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Chat_kpn_entry',
    action: 'Click_sopr',
    label: 'EndChat',
    sc: jsonQuery,
    useLastProducts: true,
  });

export const trackEntryAutorisationPopupOpen = ({ jsonQuery, label }: { jsonQuery: IJsonQuery; label: string }) =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Chat_kpn_entry_popup',
    action: 'Show_sopr',
    label,
    sc: jsonQuery,
    useLastProducts: true,
  });

export const trackEntryAutorisationPopupClick = ({ jsonQuery, label }: { jsonQuery: IJsonQuery; label: string }) =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Chat_kpn_entry_popup',
    action: 'Click_sopr',
    label,
    sc: jsonQuery,
    useLastProducts: true,
  });

export const trackOpenChatPopup = ({
  label,
  chatId,
  botId,
  userId,
}: {
  label: string;
  chatId: string;
  botId: number;
  userId?: number;
}) =>
  ca('eventSite', {
    name: 'oldevent',
    category: 'Message_popup',
    action: 'open',
    label,
    useLastProducts: true,
    page: {
      pageType: 'ListingJK',
      siteType: 'desktop',
      extra: {
        chat_id: chatId,
        bot_id: botId,
        owner_id: userId,
      },
    },
  });
